import React from "react";
import { Box } from "@mui/material";
import MenuName from "./menuNames";
import MakeMenuList from "../makeMenuItems/selectedItems";
import DeleteMenuPopover from "./deleteMenuPopover";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from 'prop-types';
import {openConfirmDeleteDialog, closeConfirmDeleteDialog, setCurrentMenu, deleteMenu } from "src/redux/slices/menuSlice";

const MenuContainer = ({
  deleteMenuName,
  confirmDelete,
  foodOrdered,
  setSelectedMenus,
  setCurrentMenuIndex,
  handleSaveMenuName,
  selectedMenus, 
  currentMenu
}) => {
  const dispatch = useDispatch();

  const { 
    // selectedMenus, currentMenu, 
    confirmDeleteDialog } = useSelector((state) => state.menus);
    console.log("selected menus and current menu in menu container", selectedMenus, currentMenu)
  // Handle menu selection
  const handleMenuSelection = (menuName) => {
    if (typeof menuName === 'string') {
      dispatch(setCurrentMenu(menuName));
      setCurrentMenuIndex(selectedMenus.findIndex((menu) => menu.menuName === menuName));
      console.log("menu selection from menuContainer", dispatch(setCurrentMenu(menuName)));
    } else {
      console.error('Expected a string for menuName');
    }
  };

  const handleOpenDeleteDialog= () => {
    dispatch(openConfirmDeleteDialog());
    console.log("opening delete dialog from popover.")
  }
  
  const handleMenuDeleteConfirm = () => {
    // dispatch(openConfirmDeleteDialog());
    dispatch(deleteMenu(deleteMenuName));
    dispatch(closeConfirmDeleteDialog());
    console.log("deleting menu from popover.")
  }
  
  const handleDeleteDialogCancel = () => {
    dispatch(closeConfirmDeleteDialog());
    console.log("closing menu from popover.")
  }

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: { xs: "column", sm: "row" },
        justifyContent: "center",
        alignItems: "center",
        gap: 3,
        ml: 2,
      }}
    >
      {selectedMenus.length > 0 && (
        <MenuName
          selectedMenus={selectedMenus}
          handleMenuSelection={handleMenuSelection} 
          handleMenuDeleteConfirm={handleMenuDeleteConfirm}
          open={confirmDeleteDialog}
        />
      )}
      {currentMenu && (
        <Box
          sx={{
            flexGrow: 1,
            display: "flex",
            justifyContent: "center",
            width: { xs: "100%", sm: "auto" },
          }}
        >
          <MakeMenuList
            currentMenu={currentMenu}
            selectedMenus={selectedMenus}
            foodOrdered={foodOrdered}
            setSelectedMenus={setSelectedMenus}
            setCurrentMenuIndex={setCurrentMenuIndex}
            deleteMenuName={deleteMenuName}
            handleOpenDelete={handleOpenDeleteDialog}
            handleMenuDelete={handleMenuDeleteConfirm}
            handleDeleteDialog={handleDeleteDialogCancel}
            handleSaveMenuName={handleSaveMenuName}
          />
        </Box>
      )}

      {confirmDeleteDialog && (
        <DeleteMenuPopover
          open={confirmDeleteDialog}
          confirmDelete={confirmDelete}
          deleteMenuName={deleteMenuName}
          handleOpenDeleteDialog={handleOpenDeleteDialog}
          handleMenuDeleteConfirm={handleMenuDeleteConfirm}
          handleDeleteDialogCancel={handleDeleteDialogCancel}
        />
      )}
    </Box>
  );
};

MenuContainer.propTypes = {
  currentMenu: PropTypes.object,
  handleMenuSelect: PropTypes.func.isRequired,
  handleDeleteMenu: PropTypes.func.isRequired,
  confirmDeleteDialog: PropTypes.bool.isRequired,
  deleteMenuName: PropTypes.string.isRequired,
  confirmDelete: PropTypes.func.isRequired,
  foodOrdered: PropTypes.array.isRequired,
  setSelectedMenus: PropTypes.func.isRequired,
  setCurrentMenuIndex: PropTypes.func.isRequired,
};

export default MenuContainer;
